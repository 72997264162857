import './index.css';
import reportWebVitals from './reportWebVitals';
import UploaderApp, { UploaderAppFn } from './UploaderApp';

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log); // eslint-disable-line no-console

interface ReactApps {
  Uploader: UploaderAppFn;
}

export interface ReactAppsNamespace {
  __REACT_APPS_NAMESPACE: ReactApps;
}

(async function createWebappUIHandlers() {
  Object.assign<Window, ReactAppsNamespace>(window, {
    __REACT_APPS_NAMESPACE: {
      Uploader: UploaderApp,
    },
  });
}());
