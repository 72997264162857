import {
  createStyles, withStyles, WithStyles,
} from '@material-ui/core/styles';
import React from 'react'; // eslint-disable-line no-unused-vars
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { useIntl } from 'react-intl';
import { CircularProgress } from '@material-ui/core';
import { TableColumnSource } from '../../models/TableColumnSource';
import TypographyRolloverIntl from '../rollovers/TypographyRolloverIntl';
import { TableDataSource } from '../../models/TableDataSource';
import { User } from '../../models/User';
import SchoolMapping from '../popup/SchoolMapping';
import { AutoCompleteSource } from '../../models/AutoCompleteSource';
import ResultPopup from '../popup/ResultPopup';
import ExportedDataPopup from '../popup/ExportedDataPopup';
import ErrorPopup from '../popup/ErrorPopup';

const stickyHeadTableStyles = () => createStyles({
  container: {
    maxHeight: 440,
  },
  tableHeaderFont: {
    fontSize: 'small',
  },
  toolbar: {
    minHeight: '35px',
    '& :hover > svg': {
      backgroundColor: 'lightgray',
      borderRadius: '50%',
    },
  },
  progressIcon: {
    position: 'relative',
    float: 'right',
    margin: '50px -160px 50px -50px',
  },
  noRowsMessage: {
    textAlign: 'center',
  },
  cellContent: {
    maxWidth: '100vw',
    wordBreak: 'break-word',
  },
});

export interface StickyHeadTableProps extends Partial<WithStyles<typeof stickyHeadTableStyles>> {
  columns: TableColumnSource[];
  rows: TableDataSource[];
  id: string;
  isLoading: boolean;
  schoolList: AutoCompleteSource[] | null;
  user: User | null;
  reloadData: () => boolean;
}

const StickyHeadTableComponent = ({
  columns, classes, rows, id, isLoading, schoolList, user, reloadData,
}: StickyHeadTableProps) => {
  const intl = useIntl();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const isValidString = (colValue: null | string): boolean => colValue !== undefined && colValue !== null && colValue.trim().length > 0;

  const previousPage = intl.formatMessage({ id: 'previousPage' });
  const nextPage = intl.formatMessage({ id: 'nextPage' });
  const uploadTypeWording = { 1: intl.formatMessage({ id: 'initialOption' }), 2: intl.formatMessage({ id: 'updateOption' }) }
  return (
    <Paper className={classes?.cellContent}>
      <TableContainer className={classes?.container}>
        <Table stickyHeader aria-label="sticky table" size="small" id={id}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  data-testid={column.testid}
                >
                  <TypographyRolloverIntl
                    messageId={column.label}
                    typographyProps={{ className: classes?.tableHeaderFont }}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading
            && (
              <TableBody>
                <TableRow>
                  <TableCell>
                    <CircularProgress size={80} className={classes?.progressIcon} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          {rows.length === 0
            && (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6} className={classes?.noRowsMessage} data-testid="noRowMessageTextLabel">
                    <TypographyRolloverIntl messageId={`noRowMessageText_${id}`} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          {!isLoading
            && (
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableRow key={index} hover role="row">
                    {columns.map((column) => {
                      if (row[column.id] === null) {
                        return (
                          <TableCell key={column.id} role="cell" align={column.align} data-testid={column.testid}>
                            <span>{column.defaultValue ?? ''}</span>
                          </TableCell>
                        );
                      }
                      const rowValue = (column.id === 'uploadType') ? uploadTypeWording[row[column.id]] : row[column.id];

                      let value: JSX.Element = <span>{intl.formatMessage({ id: rowValue })}</span>;
                      if (column.format === 'dialog') {
                        // eslint-disable-next-line no-console
                        if (row.statusId === 2) {
                          value = (
                            <SchoolMapping
                              key={column.id}
                              user={user}
                              row={row}
                              schoolList={schoolList}
                              reloadData={reloadData}
                            />
                          )
                        }

                        if (row.statusId === 1 || row.statusId === 3 || row.statusId === 5) {
                          value = <span>{intl.formatMessage({ id: 'processing' })}</span>;
                        }

                        if (row.statusId === 4) {
                          value = (
                            <ResultPopup
                              user={user}
                              uploadId={row.id}
                              reloadData={reloadData}
                              hasUnmatchedUser={isValidString(row.s3UnmatchedRecords)}
                              hasMatchedUser={isValidString(row.s3MatchedRecords)}
                            />
                          )
                        }
                        if (row.statusId === 6) {
                          value = (
                            <ExportedDataPopup
                              uploadId={row.id}
                              hasProcessingErrors={isValidString(row.s3ProcessingErrors)}
                              hasUnmatchedUser={isValidString(row.s3UnmatchedRecords)}
                            />
                          )
                        }
                        if (row.statusId === 9) {
                          value = (
                            <ErrorPopup errorMsg={row.fileUploadErrors} />
                          )
                        }
                      }
                      return (
                        <TableCell key={column.id} role="cell" align={column.align} data-testid={column.testid}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            )}
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[3, 5, 10, 25, 100]}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ size: 'small', 'aria-label': 'Next Page' }}
        backIconButtonProps={{ size: 'small', 'aria-label': 'Previous Page' }}
        backIconButtonText={previousPage}
        nextIconButtonText={nextPage}
        classes={{ toolbar: classes?.toolbar }}
        data-testid="paginationTabelLabel"
        labelRowsPerPage={<TypographyRolloverIntl messageId="rowsPerPage" />}
        labelDisplayedRows={({ from, to, count }) => (
          <span>
            <TypographyRolloverIntl messageId="records" />
            <span>{` ${from}`}</span>
            -
            <span>{`${to} `}</span>
            <TypographyRolloverIntl messageId="of" />
            <span>{` ${count} `}</span>
          </span>
        )}
      />
    </Paper>
  );
};

const StickyHeadTable = withStyles(stickyHeadTableStyles)(StickyHeadTableComponent);
StickyHeadTable.displayName = 'StickyHeadTable';

export default StickyHeadTable;
