import React, {
  useContext, useEffect, useState,
} from 'react';
import {
  createStyles, Theme, useTheme, WithStyles, withStyles,
} from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import {
  Button, OutlinedInput,
} from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';
import StyledAutoComplete from '../shared/StyledAutoComplete';
import { AutoCompleteSource } from '../../models/AutoCompleteSource';
import TypographyRolloverIntl from '../rollovers/TypographyRolloverIntl';
import { User } from '../../models/User';
import LatestProcessedUploadsTable from './LatestProcessedUploadsTable';
import PendingUploadsTable from './PendingUploadsTable';
import { TableDataSource } from '../../models/TableDataSource';
import {
  checkStatus, getNweaDistricts, getPendingUploadData, getProcessedUploadData, getSchoolPartner, getUserDistrict,
  getUserDistrictPartner, postUpload, updateUploadInfo, validateUploadInfo,
} from '../../service/api/Uploader';
import ErrorDetail from '../../util/ErrorDetail';
import awsUpload from '../../service/api/Aws';
import { AppContext } from '../../context/AppContext';

const nweaUploadPageStyles = (theme: Theme) => createStyles({
  button: {
    margin: theme.spacing(1),
    size: 'sm',
  },
  header: {
    fontSize: 'xxx-large',
    textDecoration: 'underline',
    padding: theme.spacing(3),
  },
  centerHeader: {
    textAlign: 'center',
    marginTop: '30px',
  },
  centerButton: {
    textAlign: 'center',
    width: 'auto',
    maxWidth: '330px',
  },
  loaderContainer: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    backgroundColor: 'grey',
    opacity: '0.35',
    zIndex: 9,
  },
  loader: {
    position: 'fixed',
    top: '50%',
    left: '50%',
  },
  outlinedInput: {
    width: '100%',
    // maxWidth: '330px',
  },
  columnHeaderText: {
    fontSize: 'x-large',
    textDecoration: 'underline',
  },
  column2HeaderText: {
    fontSize: 'x-large',
    textDecoration: 'underline',
    paddingBottom: '15px',
  },
  column: {
    float: 'left',
    marginLeft: '7%',
    width: '24%',
    paddingRight: '15px',
    overflow: '',
  },
  column2: {
    float: 'left',
    borderLeft: '2px gray solid',
    width: '60%',
    paddingLeft: '25px',
  },
  columnHeader: {
    marginLeft: '25%',
    paddingBottom: '10px',
  },
  smallOutlinedInput: {
    width: '100%',
  },
  row: {
    width: '100%',
  },
  schoolNameDiv: {
    paddingBottom: '10px',
  },
  errorHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingRight: '10px',
  },
});

export interface NweaUploadPageProps extends Partial<WithStyles<typeof nweaUploadPageStyles>> {
  user: User | null;
}

const NweaUploadPageComponent = ({ classes, user }: NweaUploadPageProps) => {
  const intl = useIntl();
  const [chosenDistrict, setChosenDistrict] = useState<AutoCompleteSource | null>(null);
  const [districtList, setDistrictList] = useState<AutoCompleteSource[]>([]);
  const [districtId, setDistrictId] = useState<number>(0);
  const [chosenSchool, setChosenSchool] = useState<AutoCompleteSource | null>(null);
  const [schoolList, setSchoolList] = useState<AutoCompleteSource[]>([]);
  const [showSchoolsDropDown, setShowSchoolsDropdown] = useState<boolean>(false);
  const [schoolId, setSchoolId] = useState<number | null>(null);
  const [, setSchoolName] = useState<string>('');
  const [uploadedFile, setUploadedFile] = useState<File | undefined>();
  const theme = useTheme();
  const className = (useMediaQuery(theme.breakpoints.down('md')) ? classes?.smallOutlinedInput : classes?.outlinedInput);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
  const [errorList, setErrorList] = useState<string[]>([])
  const [pendingUploadsData, setPendingUploadsData] = useState<TableDataSource[]>([]);
  const [isPendingUploadsLoading, setPendingUploadsLoading] = useState<boolean>(false);
  const [latestProcessedData, setLatestProcessedData] = useState<TableDataSource[]>([]);
  const [isLatestProcessedLoading, setLatestProcessedLoading] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);
  const [loadWording, setLoadWording] = useState<string>('');
  const [lastFetch, setLastFetch] = useState(new Date().toISOString());
  const { setErrorMessage, openDialogCount } = useContext(AppContext);

  const userDistrict = user?.districtId;
  const role = user?.role;
  const partnerId = user?.partnerId;
  const userId = user?.id;
  const languageId = user?.languageId;
  const dataRefreshRateMS = 5 * 1000;

  const validatePendingJson = (data: TableDataSource[]) => {
    if (data && data.length > 0) {
      data.forEach((x) => {
        if (x.statusId === 1 && !x.dateUpdated) {
          const dateDiffInMs = (new Date()).valueOf() - (new Date(`${x.dateUploaded}z`)).valueOf();
          // number of minutes upload created greater than 5 then make validate api call to check if file uploaded successfully
          if (dateDiffInMs / 1000 / 60 > 5) {
            validateUploadInfo(x.id);
          }
        }
      })
    }
  }
  async function fetchPendingUploadsData(selectedDistrict = 0) {
    setPendingUploadsLoading(true);
    const json = await getPendingUploadData(selectedDistrict);
    if (json instanceof ErrorDetail) {
      setPendingUploadsLoading(false);
      setErrorMessage(json.message);
      return;
    }
    setLastFetch(new Date().toISOString());
    setPendingUploadsData([...json]);
    setPendingUploadsLoading(false);
    validatePendingJson([...json])
  }

  async function fetchLatestProcessedData(selectedDistrict = 0) {
    setLatestProcessedLoading(true);
    const json = await getProcessedUploadData(selectedDistrict);
    if (json instanceof ErrorDetail) {
      setLatestProcessedLoading(false);
      setErrorMessage(json.message);
      return;
    }
    setLatestProcessedData([...json]);
    setLatestProcessedLoading(false);
  }

  async function updateUploadStatus(uploadId: number, status: string) {
    const response = await updateUploadInfo(uploadId, status);
    if (response instanceof ErrorDetail) {
      setErrorMessage(response.message);
    }
  }

  async function validateUpload(uploadId: number) {
    const uploaderStatusResponse = await validateUploadInfo(uploadId);
    if (uploaderStatusResponse instanceof ErrorDetail) {
      setErrorMessage(uploaderStatusResponse.message);
    }
  }

  const reloadData = () => {
    fetchPendingUploadsData(districtId);
    fetchLatestProcessedData(districtId);
    return true;
  }

  const attachFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;

    if (!fileList) return;
    setUploadedFile(fileList[0]);
    e.target.value = ''
  }

  async function clearSubmitForm() {
    setErrorList([])
    setUploadedFile(undefined);
  }

  async function submitForm() {
    const submitErrorList = [];

    if (districtId === undefined || districtId === 0) {
      submitErrorList.push('chooseDistrictError')
    }

    if (uploadedFile === undefined) {
      submitErrorList.push('attachFile')
    }

    if (schoolId === undefined || schoolId === null) {
      submitErrorList.push('chooseSchoolError')
    }

    if (submitErrorList.length > 0) {
      setErrorList(submitErrorList);
      return;
    }
    setIsSubmitDisabled(true)
    setIsUploadLoading(false);
    setLoadWording('Uploading')

    const formData = new FormData();

    // Actual file has to be appended last.
    if (uploadedFile !== undefined) {
      formData.append('file', uploadedFile)
    }
    const presignedPostData = await postUpload(districtId, schoolId, formData)
    if (presignedPostData instanceof ErrorDetail) {
      setIsSubmitDisabled(false)
      setIsUploadLoading(false);
      setLoadWording('Error Uploading');
      // Todo add error Pop up or add error to state some where insted of console
      setErrorMessage(presignedPostData.message);
      return;
    }

    const awsResponse = await awsUpload(presignedPostData, uploadedFile)
    if (awsResponse instanceof ErrorDetail) {
      setIsUploadLoading(false);
      setIsSubmitDisabled(false);
      setErrorMessage(awsResponse.message);
      await updateUploadStatus(presignedPostData.uploadId, 'UPLOAD_FAILED');
      return;
    }

    setLoadWording('Validating Columns');
    await validateUpload(presignedPostData.uploadId);
    setIsUploadLoading(false);
    fetchPendingUploadsData(districtId);
    fetchLatestProcessedData(districtId);
    clearSubmitForm();
    setIsSubmitDisabled(false);
    setLoadWording('');
  }

  async function fetchSchoolList(id: number) {
    setShowSchoolsDropdown(true);

    const schools = await getSchoolPartner(id);
    if (schools instanceof ErrorDetail) {
      setErrorMessage(schools.message);
      return
    }
    setSchoolList(schools);
    if (schools.length === 1) {
      if ('name' in schools[0]) {
        setSchoolName(schools[0].name);
        setSchoolId(schools[0].id);
      }
    }
  }

  async function fetchData() {
    if (role === 'KBA') {
      const districts = await getNweaDistricts();
      if (districts instanceof ErrorDetail) {
        setErrorMessage(districts.message);
        return;
      }
      setDistrictList(districts);
    } else if (role === 'DISTRICT_ADMIN') {
      setIsInitialized(true);
      setShowSchoolsDropdown(false);
      if (userDistrict) {
        setDistrictId(userDistrict);
      }

      if (userDistrict === undefined || partnerId === undefined) {
        // Todo add error Pop up
        return;
      }
      const schools = await getUserDistrict(userDistrict, partnerId);
      if (schools instanceof ErrorDetail) {
        setErrorMessage(schools.message);
        return;
      }
      setSchoolList(schools);
      if (schools.length > 0) {
        setShowSchoolsDropdown(true);
      }
      if (schools.length === 1) {
        if ('name' in schools[0]) {
          setSchoolName(schools[0].name);
          setSchoolId(schools[0].id);
        }
      }
    } else if (role === 'SCHOOL_ADMIN') {
      setIsInitialized(true);
      setShowSchoolsDropdown(false);
      if (userDistrict) {
        setDistrictId(userDistrict);
      }

      if (userDistrict === undefined || partnerId === undefined || userId === undefined) {
        // Todo add error Pop up
        return;
      }
      const schools = await getUserDistrictPartner(userDistrict, partnerId, userId);
      if (schools instanceof ErrorDetail) {
        setErrorMessage(schools.message);
        return;
      }
      setSchoolList(schools);
      if (schools.length > 0) {
        setShowSchoolsDropdown(true);
      }
      if (schools.length === 1) {
        if ('name' in schools[0]) {
          setSchoolName(schools[0].name);
          setSchoolId(schools[0].id);
        }
      }
    }
  }

  function handleDistrictIdChange(value: React.SetStateAction<AutoCompleteSource | null>): void {
    setChosenDistrict(value);
    setIsInitialized(false);
    setChosenSchool(null);
    setSchoolList([]);
    setSchoolId(null);
    setDistrictId(0);
    setUploadedFile(undefined);
    setShowSchoolsDropdown(false);
    setSchoolName('');
    if (value !== null) {
      if ('id' in value) {
        setDistrictId(value.id);
        fetchSchoolList(value.id);
        fetchPendingUploadsData(value.id);
        fetchLatestProcessedData(value.id);
        setIsInitialized(true);
      }
    } else {
      fetchPendingUploadsData();
      fetchLatestProcessedData();
    }
  }

  function handleSchoolIdChange(value: React.SetStateAction<AutoCompleteSource | null>): void {
    setChosenSchool(value);

    if (value === null) {
      setSchoolId(null);
      return;
    }

    if ('id' in value) {
      setSchoolId(value.id);
    }
  }

  useEffect(
    () => {
      setChosenSchool(null);
      setChosenDistrict(null);
      setUploadedFile(undefined);
      setSchoolList([]);
      setDistrictList([]);
      setSchoolId(null);
      setDistrictId(0);
      setUploadedFile(undefined);
      setShowSchoolsDropdown(false);
      setSchoolName('');
      fetchData();
      fetchPendingUploadsData();
      fetchLatestProcessedData();
    },
    [user, languageId],
  );

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const res = await checkStatus(districtId, lastFetch);
      if (res instanceof ErrorDetail) {
        return;
      }
      if (res === true && openDialogCount === 0) {
        reloadData();
      }
    }, dataRefreshRateMS);
    return () => clearInterval(intervalId);
  }, [districtId, lastFetch, openDialogCount]);

  const uploadInputRef = React.useRef<HTMLInputElement>(null);
  const handleAttachedClick = () => {
    if (uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  }

  return (
    <>
      {isUploadLoading
        && (
          <div className={classes?.loaderContainer}>
            <div className={classes?.loader}>
              <span style={{ position: 'absolute', top: '10px', left: '2px' }}>{loadWording}</span>
              <CircularProgress color="primary" size={60} thickness={4.5} />
            </div>
          </div>
        )}
      <div className={classes?.centerHeader} role="heading" aria-level={1} data-testid="nweaImportHeaderLabel">
        <TypographyRolloverIntl
          messageId="nweaImportHeader"
          typographyProps={{ className: classes?.header }}
        />
      </div>
      <div className={classes?.row}>
        <div className={classes?.column}>
          {errorList.length > 0
            && (
              <div className={classes?.columnHeader}>
                <h2 data-testid="errorHeaderLabel">
                  <TypographyRolloverIntl
                    messageId="errors"
                    typographyProps={{ className: classes?.errorHeader }}
                  />
                </h2>
                {errorList.map((message) => (
                  <li data-testid={`${message}Label`}>
                    <TypographyRolloverIntl messageId={message} />
                  </li>
                ))}
              </div>
            )}
          <div className={classes?.columnHeader} data-testid="newUploadLabel">
            <TypographyRolloverIntl
              messageId="newUpload"
              typographyProps={{ className: classes?.columnHeaderText }}
            />
          </div>
          {role === 'KBA'
            && (
              <StyledAutoComplete
                id="chooseDistrict"
                list={districtList}
                chosenItem={chosenDistrict}
                label="chooseDistrict"
                handleIdChange={handleDistrictIdChange}
                displayAll={false}
              />
            )}
          {showSchoolsDropDown
            && (
              <StyledAutoComplete
                id="chooseSchool"
                list={schoolList}
                chosenItem={chosenSchool}
                label="chooseSchool"
                handleIdChange={handleSchoolIdChange}
                displayAll
              />
            )}
          {isInitialized
            && (
              <div className={classes?.centerContainer}>
                {uploadedFile !== undefined && (
                  <OutlinedInput
                    id="uploadFileName"
                    className={className}
                    readOnly
                    value={uploadedFile?.name ? uploadedFile?.name : intl.formatMessage({ id: 'chooseFile' })}
                  />
                )}
                <div className={classes?.centerButton}>
                  <input
                    id="raisedButtonFile"
                    name="raisedButtonFile"
                    style={{ display: 'none', marginLeft: '-20%' }}
                    type="file"
                    accept=".csv"
                    onChange={attachFile}
                    ref={uploadInputRef}
                  />
                  <Button
                    variant="contained"
                    color="default"
                    className={classes?.button}
                    startIcon={<AttachmentIcon />}
                    component="button"
                    onClick={handleAttachedClick}
                    data-testid="attachFileButtonLabel"
                  >
                    <TypographyRolloverIntl messageId="attachFile" />
                  </Button>
                </div>
                <div className={classes?.centerButton}>
                  <Button
                    variant="contained"
                    id="submitButton"
                    color="default"
                    disabled={isSubmitDisabled}
                    className={classes?.button}
                    startIcon={<CloudUploadIcon />}
                    component="button"
                    onClick={submitForm}
                  >
                    <TypographyRolloverIntl messageId="submit" />
                  </Button>
                </div>
              </div>
            )}
        </div>
        <div className={classes?.column2}>
          <div style={{ paddingBottom: '10px', textAlign: 'center' }} data-testid="pendingUploadsLabel">
            <TypographyRolloverIntl
              messageId="pendingUploads"
              typographyProps={{ className: classes?.column2HeaderText }}
            />
          </div>
          <PendingUploadsTable
            user={user}
            isLoading={isPendingUploadsLoading}
            data={pendingUploadsData}
            schoolList={schoolList}
            reloadData={reloadData}
          />
          <div style={{ padding: '10px 0px', textAlign: 'center' }} data-testid="latestUploadsLabel">
            <TypographyRolloverIntl messageId="latestUploads" typographyProps={{ className: classes?.column2HeaderText }} />
          </div>
          <LatestProcessedUploadsTable user={user} isLoading={isLatestProcessedLoading} data={latestProcessedData} reloadData={reloadData} />
        </div>
      </div>
    </>
  );
};

const NweaUploadPage = withStyles(nweaUploadPageStyles)(NweaUploadPageComponent);
NweaUploadPage.displayName = 'NweaUploadPage';

export default NweaUploadPage;
