import AxiosInstanceInitializer, { NetworkManagerConfig } from './AxiosInstanceInitializer';
import appConfig from '../../appConfig';

class Uploader extends AxiosInstanceInitializer {
  private kidbizHost;

  constructor() {
    const config = {
      baseURL: appConfig.UPLOADER_HOST,
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
    } as NetworkManagerConfig;
    const isEnabledLogging = process.env.REACT_APP_ENABLE_LOGGING === 'true';
    super(config, { enableLogging: isEnabledLogging });
    this.kidbizHost = appConfig.KIDBIZ_HOST;
  }

  setRequestInterceptor(): void {
    this.getAxiosInstance().interceptors.request.use(async (request) => {
      try {
        if (!request.headers) {
          return request;
        }
        const response = await fetch(`${this.kidbizHost}/external-assessment/access-token`, {
          mode: 'cors',
          credentials: 'include',
        });
        let token = '';
        if (response.ok) {
          const auth = await response.json();
          token = auth.token;
          this.printResponse(auth);
        } else {
          return Promise.reject(new Error('unauthorized'));
        }
        request.headers.Authorization = `Bearer ${token}`;
        return request;
      } catch (err) {
        this.printResponse(err);
        return Promise.reject(err)
      }
    });
  }
}

export default new Uploader().getAxiosInstance();
