import React from 'react';
import {
  createStyles, Theme, WithStyles, withStyles,
} from '@material-ui/core/styles';
import StickyHeadTable from '../shared/StickyHeadTable';
import { TableColumnSource } from '../../models/TableColumnSource';
import { TableDataSource } from '../../models/TableDataSource';
import { AutoCompleteSource } from '../../models/AutoCompleteSource';
import { User } from '../../models/User';

const PendingUploadsTableStyles = (theme: Theme) => createStyles({
  button: {
    margin: theme.spacing(1),
  },
  header: {
    fontSize: 'xxx-large',
    textDecoration: 'underline',
    padding: theme.spacing(3),
  },
  centerHeader: {
    textAlign: 'center',
    marginTop: '30px',
  },
  outlinedInput: {
    width: '330px',
  },
  schoolNameDiv: {
    paddingBottom: '10px',
  },
  columnHeaderText: {
    fontSize: 'x-large',
    textDecoration: 'underline',
  },
  column: {
    float: 'left',
    marginLeft: '13%',
  },
  columnHeader: {
    marginLeft: '20%',
  },
  fileNameBox: {
    width: '330px',
  },
});

export interface PendingUploadsTableProps extends Partial<WithStyles<typeof PendingUploadsTableStyles>> {
  user: User | null
  data: TableDataSource[]
  isLoading: boolean
  schoolList: AutoCompleteSource[]
  reloadData: () => boolean
}

const PendingUploadsTableComponent = ({
  user, data, isLoading, schoolList, reloadData,
}: PendingUploadsTableProps) => {
  const columns: TableColumnSource[] = [
    {
      id: 'schoolName', label: 'schools', minWidth: 70, size: 'small', testid: 'schoolsLabel',
    },
    {
      id: 'originalFileName', label: 'fileName', minWidth: 70, size: 'small', testid: 'fileNameLabel',
    },
    {
      id: 'userName', label: 'uploadedBy', minWidth: 70, size: 'small', testid: 'uploadByLabel',
    },
    {
      id: 'statusName', label: 'uploadStatus', minWidth: 70, size: 'small', testid: 'uploadStatusLabel',
    },
    {
      id: 'review', label: 'nextStep', minWidth: 70, format: 'dialog', size: 'small', testid: 'nextStepLabel',
    },
  ];

  return (
    <>
      <StickyHeadTable
        key={1}
        columns={columns}
        rows={data}
        id="pendingUploadsTable"
        isLoading={isLoading}
        schoolList={schoolList}
        user={user}
        reloadData={reloadData}
      />
    </>
  );
};

const PendingUploadsTable = withStyles(PendingUploadsTableStyles)(PendingUploadsTableComponent);
PendingUploadsTable.displayName = 'PendingUploadsTable';

export default PendingUploadsTable;
