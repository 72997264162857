import { TextField } from '@material-ui/core';
import {
  createStyles, useTheme, withStyles, WithStyles,
} from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect } from 'react'; // eslint-disable-line no-unused-vars
import { useIntl } from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AutoCompleteSource } from '../../models/AutoCompleteSource';

const autoCompleteStyles = () => createStyles({
  autoCompleteBox: {
    width: 'auto',
    alignSelf: 'center',
    margin: '0',
    padding: '0px 10px 10px 0px',
    /* Hide IE 11 clear x button */
    msClear: { display: 'none' },
    alignItems: 'center',
  },
  smallScreen: {
    width: 'auto',
    margin: '0 auto',
    alignSelf: 'center',
    padding: '0px 10px 10px 0px',
    /* Hide IE 11 clear x button */
    msClear: { display: 'none' },
  },
});

export interface AutoCompleteProps extends Partial<WithStyles<typeof autoCompleteStyles>> {
  id: string;
  list: AutoCompleteSource[];
  chosenItem: AutoCompleteSource | null;
  label: string;
  handleIdChange: (value: AutoCompleteSource | null) => void;
  displayAll: boolean;
  disableAutoSelect?: boolean;
}

const AutoCompleteComponent = ({
  id, chosenItem, handleIdChange, label, list, classes, displayAll, disableAutoSelect,
}: AutoCompleteProps) => {
  const intl = useIntl();
  let fullList = list;
  if (displayAll && list.length > 1) {
    const multipleSchools = intl.formatMessage({ id: 'multipleSchools' });
    fullList = [{ id: 0, name: multipleSchools }, ...list];
  }

  useEffect(
    () => {
      if (list.length === 1 && chosenItem?.id !== list[0].id && !disableAutoSelect) {
        handleIdChange({ id: list[0].id, name: list[0].name });
      }
    },
    [list.length],
  );
  const theme = useTheme();
  const className = (useMediaQuery(theme.breakpoints.down('md')) ? classes?.smallScreen : classes?.autoCompleteBox);

  return (
    <>
      <Autocomplete
        id={id}
        value={chosenItem}
        disabled={!disableAutoSelect && fullList.length === 1 && chosenItem !== null}
        loading
        options={fullList}
        getOptionLabel={(option: { name: string }) => option.name}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={(event, value: AutoCompleteSource | null) => {
          if (chosenItem?.id !== value?.id) {
            handleIdChange(value);
          }
        }}
        className={className}
        clearOnEscape={list.length !== 1}
        disableClearable={!disableAutoSelect && list.length === 1 && chosenItem !== null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={intl.formatMessage({ id: label })}
            variant="outlined"
          />
        )}
      />
    </>
  );
};
const StyledAutoComplete = withStyles(autoCompleteStyles)(AutoCompleteComponent);
StyledAutoComplete.displayName = 'AutoComplete';

export default StyledAutoComplete;
