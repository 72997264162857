import React, { useCallback, useContext } from 'react';
import {
  createStyles, Theme, WithStyles, withStyles,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useIntl } from 'react-intl';
import LinearProgress from '../Animations/LinearProgress';
import TypographyRolloverIntl from '../rollovers/TypographyRolloverIntl';
import { User } from '../../models/User';
import { getDownloadLink, postNextStep, postRejectUpload } from '../../service/api/Uploader';
import ErrorDetail from '../../util/ErrorDetail';
import fileDownload from '../../util/FileDownload';
import AWS_FILE_ENUM from '../../enum/AWSFileEnum';
import { AppContext } from '../../context/AppContext';

const resultPopupStyle = (theme: Theme) => createStyles({
  closeButtonX: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  root: {
    '& > *': {
      margin: theme.spacing(2),
    },
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  xSmall: {
    fontSize: 'x-small',
    width: '100px',
  },
  containerStyle: {
    overflow: 'hidden',
    alignItems: 'center',
    textAlign: 'center',
    height: 20,
    width: '100%',
    color: 'red',
    marginTop: '2rem',
    cursor: 'pointer',
  },
  buttonLinkA: {
    marginTop: '50px',
    paddingLeft: '150px',
    paddingRight: '150px',
    paddingButton: '100px',
    marginButton: '50px',
    top: '50px',
  },
  buttonLinkB: {
    marginTop: '100px',
    paddingLeft: '150px',
    paddingRight: '150px',
    marginButton: '50px',
    paddingButton: '100px',
    button: '50px',
  },
  btnDisabled: {
    opacity: 0.7,
  },
  btnGrey: {
    backgroundColor: '#595959',
  },
});

export interface ResultPopupProps extends Partial<WithStyles<typeof resultPopupStyle>> {
  user: User | null;
  uploadId: number;
  hasMatchedUser: boolean;
  hasUnmatchedUser: boolean;
  reloadData: () => boolean;
}

const ResultPopupComponent = ({
  classes, uploadId, hasUnmatchedUser, hasMatchedUser, reloadData,
}: ResultPopupProps) => {
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(false);
  const intl = useIntl();
  const [error, setError] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const { setOpenDialogCount, setErrorMessage } = useContext(AppContext);

  const setFileDownloadError = (id: string) => {
    setError(intl.formatMessage({ id }));
    setTimeout(() => {
      setError('')
    }, 5000);
  }
  const fetchMatched = async () => {
    const response = await getDownloadLink(uploadId, AWS_FILE_ENUM.MATCHED_RECORDS);
    if (response instanceof ErrorDetail) {
      setFileDownloadError('errorDownloadingMatched');
      return;
    }
    fileDownload(response.urlFull, `${response.uploadId}-matched.csv`);
  }

  const fetchUnMatched = async () => {
    const response = await getDownloadLink(uploadId, AWS_FILE_ENUM.UNMATCHED_RECORDS);
    if (response instanceof ErrorDetail) {
      setFileDownloadError('errorDownloadingUnMatched');
      return;
    }
    fileDownload(response.urlFull, `${response.uploadId}-unmatched.csv`);
  }

  const rejectDownload = async () => {
    setIsLoading(true);
    const response = await postRejectUpload(uploadId);
    setIsLoading(false);
    if (response instanceof ErrorDetail) {
      setErrorMessage(response.message);
    }
    reloadData();
  }

  const handleClickOpen = () => {
    setOpenDialogCount(1);
    setOpen(true);
  };
  const handleClose = () => {
    setOpenDialogCount(0);
    setOpen(false);
  };

  const handleEscapeKey = useCallback((event) => {
    if (event.keyCode === 27) {
      event.preventDefault();
      if (handleClose) handleClose()
    }
  }, []);

  const handleReject = () => {
    rejectDownload();
    handleClose();
  };

  const onClickProcess = async () => {
    setIsLoading(true);
    const response = await postNextStep(uploadId);
    setIsLoading(false);
    if (response instanceof ErrorDetail) {
      setErrorMessage(response.message);
      return;
    }
    handleClose();
    reloadData();
  }

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleClickOpen} data-testid="resultPopupLabel">
        <TypographyRolloverIntl
          messageId="reviewButton"
          typographyProps={{ className: classes?.xSmall }}
        />
      </Button>
      <Dialog
        fullWidth={fullWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="result-popup"
        onKeyUp={handleEscapeKey}
      >
        <DialogTitle hidden id="result-popup">
          <TypographyRolloverIntl
            messageId="reviewButton"
            typographyProps={{ className: classes?.xSmall }}
          />
        </DialogTitle>

        <Button
          onClick={fetchMatched}
          disabled={!hasMatchedUser}
          component="button"
          className={hasMatchedUser ? classes?.buttonLinkA : `${classes?.buttonLinkA} ${classes?.btnDisabled}`}
          data-testid="reviewMatchedUserLabel"
        >
          <TypographyRolloverIntl
            messageId="reviewMatchedUser"
            typographyProps={{ className: 'btn-download' }}
          />
        </Button>
        <Button
          disabled={!hasUnmatchedUser}
          onClick={fetchUnMatched}
          component="button"
          className={hasUnmatchedUser ? classes?.buttonLinkB : `${classes?.buttonLinkB} ${classes?.btnDisabled}`}
          data-testid="reviewUnmatchedUserLabel"
        >
          <TypographyRolloverIntl
            messageId="reviewUnmatchedUser"
            typographyProps={{ className: 'btn-download' }}
          />
        </Button>
        <IconButton
          aria-label={intl.formatMessage({ id: 'closeX' })}
          className={classes?.closeButtonX}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText className={classes?.containerStyle} onClick={() => setError('')}>
            {error}
          </DialogContentText>
          {isLoading && <LinearProgress />}
        </DialogContent>
        <DialogActions>
          <div className={classes?.root}>
            <Button
              onClick={onClickProcess}
              data-testid="processButtonLabel"
              disabled={!hasMatchedUser || isLoading}
            >
              <TypographyRolloverIntl
                messageId="processButton"
                typographyProps={
                  {
                    className: hasMatchedUser && !isLoading
                      ? 'btn-cs btn-medium color-primary'
                      : `btn-cs btn-medium color-primary ${classes?.btnDisabled}`,
                  }
                }
              />
            </Button>
            <Button
              onClick={handleReject}
              data-testid="rejectButtonLabel"
              disabled={isLoading}
            >
              <TypographyRolloverIntl
                messageId="rejectButton"
                typographyProps={
                  {
                    className: !isLoading
                      ? 'btn-cs btn-medium color-danger'
                      : `btn-cs btn-medium color-danger ${classes?.btnDisabled}`,
                  }
                }
              />
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ResultPopup = withStyles(resultPopupStyle)(ResultPopupComponent);
ResultPopup.displayName = 'ResultPopup';

export default ResultPopup;
