import React from 'react';
import { IntlProvider } from 'react-intl';
import AppMessages from './AppMessages';

export type LanguageCodes = 'en' | 'es' | 'en-es';
interface TranslationProviderProps {
  children: React.ReactNode;
  language: LanguageCodes;
}
const TranslationProvider = (props: TranslationProviderProps) => {
  const { children, language } = props;
  const translations = AppMessages[language];
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleError = () => {};
  return (
    <IntlProvider
      locale={language}
      messages={translations}
      onError={handleError}
    >
      {children}
    </IntlProvider>
  );
};

export default TranslationProvider;
