// eslint-disable-next-line no-shadow
enum AWS_FILE_ENUM {
  ORIGINAL_UPLOAD = 'ORIGINAL_UPLOAD',
  MATCHED_RECORDS = 'MATCHED_RECORDS',
  UNMATCHED_RECORDS = 'UNMATCHED_RECORDS',
  PROCESSING_ERRORS = 'PROCESSING_ERRORS',
  PROCESSED_DATA = 'PROCESSED_DATA'
}

export default AWS_FILE_ENUM;
