import { PresignedData } from '../../models/PresignedData'
import ErrorDetail from '../../util/ErrorDetail'

export default async (presignedPostData: PresignedData, uploadedFile: File | undefined):Promise<boolean|ErrorDetail> => {
  try {
    const awsUrl = presignedPostData.urlFull;
    if (presignedPostData.url.includes('.amazonaws.com')) {
      const response = await fetch(awsUrl, {
        method: 'PUT', body: uploadedFile, mode: 'cors', credentials: 'omit',
      });
      if (!response.ok) {
        return new ErrorDetail(new Error(`Failed to upload csv file ${response.statusText}`));
      }
      return true;
    }
    return new ErrorDetail(new Error('Invalid AWS presigned URL'));
  } catch (err) {
    return new ErrorDetail(err);
  }
}

//  Left these as it was in the code before

// const presignedPostData = {
//   url: 'http://localhost:4566',
//   // eslint-disable-next-line max-len
// eslint-disable-next-line max-len
//   urlFull: 'http://localhost:4566/coolbucketname/20210922/156/2/upload.csv?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20210922T214021Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3599&X-Amz-Credential=~%EF%BF%BD%1Bj%2F20210922%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=8293b2cb6a1b88d7fdf34440ee3f27c3269812c13912dd2af1719ee410034de3',
//   key: '20210922/156/2/upload.csv',
//   bucket: 'coolbucketname',
//   uploadId: 1,
//   xamzAlgorithm: 'AWS4-HMAC-SHA256',
//   xamzDate: '20210922T214021Z',
//   xamzSignedHeaders: 'host',
//   xamzExpires: '3599',
//   xamzCredential: '~%EF%BF%BD%1Bj%2F20210922%2Fus-east-1%2Fs3%2Faws4_request',
//   xamzSignature: '8293b2cb6a1b88d7fdf34440ee3f27c3269812c13912dd2af1719ee410034de3',
// }
