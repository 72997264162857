import React, { useEffect, useState } from 'react';
import {
  createGenerateClassName, createTheme, StylesProvider, Theme, ThemeProvider,
} from '@material-ui/core/styles';
import { enUS, esES } from '@material-ui/core/locale';
import AppContextProvider from './context/AppContext';

import TranslationProvider, { LanguageCodes } from './components/TranslationProvider';
import { User } from './models/User';
import { Language } from './models/Language';
import NweaUploadPage from './components/UploadPage/NweaUploadPage';
import { getUserInfo } from './service/api/Uploader';
import keepAliveAPI from './service/api/Kidbiz';
import ErrorDetail from './util/ErrorDetail';
import Alert from './components/popup/Alert';

const keepAliveFrequency = (Number(process.env.REACT_APP_KEEP_ALIVE));

// all css class names are not prefixed on local env for better debugging purposes
let generateClassName = createGenerateClassName();
if (process.env.REACT_APP_CSS_PREFIXED === 'true') {
  generateClassName = createGenerateClassName({
    seed: 'nwea',
  });
}

const App = () => {
  const spanish = createTheme({
  }, esES);
  const english = createTheme({
  }, enUS);

  const [user, setUser] = useState<User | null>(null);
  const [muiLanguage, setMuiLanguage] = useState<Theme>(english);
  const [selectedLanguage, setSelectedLanguage] = useState<Language | null>(null);
  const languages = [{ id: 1, name: 'en' }, { id: 2, name: 'es' }, { id: 3, name: 'en-es' }, { id: 7, name: 'en' }];

  const langAbrev = selectedLanguage?.name ? selectedLanguage.name : 'en';

  const getLangAbrev = (newUser: User) => {
    if (selectedLanguage?.id === newUser.languageId) {
      return;
    }
    setSelectedLanguage(languages.find((s) => s.id === newUser?.languageId) as Language);
    if (newUser?.languageId === 1 || newUser?.languageId === 7) {
      setMuiLanguage(english);
    } else {
      setMuiLanguage(spanish);
    }
  };

  const keepAlive = () => {
    keepAliveAPI();
  }

  async function fetchData() {
    const response = await getUserInfo() as User | ErrorDetail;
    if (response instanceof ErrorDetail) {
      return
    }
    if (response !== user) {
      setUser(response);
      getLangAbrev(response);
    }
  }

  useEffect(() => {
    fetchData();
    // Keep Alive
    const intervalId = setInterval(keepAlive, keepAliveFrequency);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <main>
      <div className="App">
        <StylesProvider generateClassName={generateClassName}>
          <ThemeProvider theme={muiLanguage}>
            <TranslationProvider language={langAbrev as LanguageCodes}>
              <AppContextProvider>
                <Alert />
                <NweaUploadPage
                  user={user}
                />
              </AppContextProvider>
            </TranslationProvider>
          </ThemeProvider>
        </StylesProvider>
      </div>
    </main>
  );
}

export default App;
