import React, { useCallback, useContext } from 'react';
import {
  createStyles, Theme, WithStyles, withStyles,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useIntl } from 'react-intl';
import TypographyRolloverIntl from '../rollovers/TypographyRolloverIntl';
import { getDownloadLink } from '../../service/api/Uploader';
import ErrorDetail from '../../util/ErrorDetail';
import fileDownload from '../../util/FileDownload';
import AWS_FILE_ENUM from '../../enum/AWSFileEnum';
import { AppContext } from '../../context/AppContext';

const resultPopupStyle = (theme: Theme) => createStyles({
  closeButtonX: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  root: {
    '& > *': {
      margin: theme.spacing(2),
    },
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  xSmall: {
    fontSize: 'x-small',
    width: '100px',
  },
  containerStyle: {
    overflow: 'hidden',
    alignItems: 'center',
    textAlign: 'center',
    height: 20,
    width: '100%',
    color: 'red',
    marginTop: '2rem',
    cursor: 'pointer',
  },
  buttonLinkA: {
    marginTop: '25px',
    paddingLeft: '150px',
    paddingRight: '150px',
    paddingButton: '100px',
    marginButton: '25px',
    top: '50px',
  },
});

export interface ResultPopupProps extends Partial<WithStyles<typeof resultPopupStyle>> {
  uploadId: number;
  hasUnmatchedUser: boolean;
  hasProcessingErrors: boolean;
}

const ExportedDataComponent = ({
  classes, uploadId, hasUnmatchedUser, hasProcessingErrors,
}: ResultPopupProps) => {
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(false);
  const intl = useIntl();
  const [error, setError] = React.useState('');
  const { setOpenDialogCount } = useContext(AppContext);

  const setFileDownloadError = (id: string) => {
    setError(intl.formatMessage({ id }));
    setTimeout(() => {
      setError('')
    }, 5000);
  }
  const fetchProcessedData = async () => {
    const response = await getDownloadLink(uploadId, AWS_FILE_ENUM.PROCESSED_DATA);
    if (response instanceof ErrorDetail) {
      setFileDownloadError('errorDownloadingProcessed');
      return;
    }
    fileDownload(response.urlFull, `${response.uploadId}-processed.csv`);
  }

  const fetchUnProcessedData = async () => {
    const response = await getDownloadLink(uploadId, AWS_FILE_ENUM.PROCESSING_ERRORS);
    if (response instanceof ErrorDetail) {
      setFileDownloadError('errorDownloadingUnProcessed');
      return;
    }
    fileDownload(response.urlFull, `${response.uploadId}-processing_errors.csv`);
  }

  const fetchUnMatched = async () => {
    const response = await getDownloadLink(uploadId, AWS_FILE_ENUM.UNMATCHED_RECORDS);
    if (response instanceof ErrorDetail) {
      setFileDownloadError('errorDownloadingUnMatched');
      return;
    }
    fileDownload(response.urlFull, `${response.uploadId}-unmatched.csv`);
  }

  const handleClickOpen = () => {
    setOpenDialogCount(1);
    setOpen(true);
  };
  const handleClose = () => {
    setOpenDialogCount(0);
    setOpen(false);
  };

  const handleEscapeKey = useCallback((event) => {
    if (event.keyCode === 27) {
      event.preventDefault();
      if (handleClose) handleClose()
    }
  }, []);

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleClickOpen} data-testid="exportedDataPopupLabel">
        <TypographyRolloverIntl
          messageId="processedSuccess"
          typographyProps={{ className: classes?.xSmall }}
        />
      </Button>
      <Dialog
        fullWidth={fullWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-title-processed"
        onKeyUp={handleEscapeKey}
      >
        <DialogTitle hidden id="dialog-title-processed">
          <TypographyRolloverIntl
            messageId="processedSuccess"
          />
        </DialogTitle>
        <Button
          onClick={fetchProcessedData}
          component="button"
          className={classes?.buttonLinkA}
          data-testid="reviewProcessedUserLabel"
        >
          <TypographyRolloverIntl
            messageId="reviewProcessedUser"
            typographyProps={{ className: 'btn-download' }}
          />
        </Button>

        {hasProcessingErrors
          && (
            <Button
              onClick={fetchUnProcessedData}
              component="button"
              className={classes?.buttonLinkA}
              data-testid="reviewUnProcessedUserLabel"
            >
              <TypographyRolloverIntl
                messageId="reviewUnProcessedUser"
                typographyProps={{ className: 'btn-download' }}
              />
            </Button>
          )}
        {hasUnmatchedUser
          && (
            <Button
              onClick={fetchUnMatched}
              component="button"
              className={classes?.buttonLinkA}
              data-testid="reviewUnmatchedUserLabelProcessed"
            >
              <TypographyRolloverIntl
                messageId="reviewUnmatchedUser"
                typographyProps={{ className: 'btn-download' }}
              />
            </Button>
          )}

        <IconButton
          aria-label={intl.formatMessage({ id: 'closeX' })}
          className={classes?.closeButtonX}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText className={classes?.containerStyle} onClick={() => setError('')}>
            {error}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

const ResultPopup = withStyles(resultPopupStyle)(ExportedDataComponent);
ResultPopup.displayName = 'ResultPopup';

export default ResultPopup;
