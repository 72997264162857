let KIDBIZ_HOST = '';
let UPLOADER_HOST = '';

let kbUrl = process.env.REACT_APP_KIDBIZ_HOST
if (window.location.host.indexOf('.net') !== -1) {
  kbUrl = process.env.REACT_APP_KIDBIZ_HOST_NET;
}
if (process.env.REACT_APP_ENV === 'qa') {
  kbUrl = window.location.origin;
}
KIDBIZ_HOST = kbUrl;
UPLOADER_HOST = process.env.REACT_APP_UPLOADER_HOST;

const config = {
  KIDBIZ_HOST,
  UPLOADER_HOST,
};
export default config;
