import React from 'react';
import {
  createStyles, Theme, WithStyles, withStyles,
} from '@material-ui/core/styles';
import StickyHeadTable from '../shared/StickyHeadTable';
import { TableColumnSource } from '../../models/TableColumnSource';
import { TableDataSource } from '../../models/TableDataSource';
import { User } from '../../models/User';

const LatestProcessedUploadsTableStyles = (theme: Theme) => createStyles({
  button: {
    margin: theme.spacing(1),
  },
  header: {
    fontSize: 'xxx-large',
    textDecoration: 'underline',
    padding: theme.spacing(3),
  },
  centerHeader: {
    textAlign: 'center',
    marginTop: '30px',
  },
  outlinedInput: {
    width: '330px',
  },
  schoolNameDiv: {
    paddingBottom: '10px',
  },
  columnHeaderText: {
    fontSize: 'x-large',
    textDecoration: 'underline',
  },
  column: {
    float: 'left',
    marginLeft: '13%',
  },
  columnHeader: {
    marginLeft: '20%',
  },
  fileNameBox: {
    width: '330px',
  },
});

export interface LatestProcessedUploadsTableProps extends Partial<WithStyles<typeof LatestProcessedUploadsTableStyles>> {
  data: TableDataSource[]
  isLoading: boolean
  user: User | null
  reloadData: () => boolean
}

const LatestProcessedUploadsTableComponent = ({
  data, isLoading, user, reloadData,
}: LatestProcessedUploadsTableProps) => {
  const columns: TableColumnSource[] = [
    {
      id: 'schoolName', label: 'schools', minWidth: 70, size: 'small', testid: 'schoolsLabel',
    },
    {
      id: 'originalFileName', label: 'fileName', minWidth: 70, size: 'small', testid: 'fileNameLabel',
    },
    {
      id: 'reviewedByName', label: 'reviewedBy', minWidth: 70, size: 'small', testid: 'reviewedByLabel', defaultValue: 'N/A',
    },
    {
      id: 'statusName', label: 'uploadStatus', minWidth: 70, size: 'small', testid: 'uploadStatusLabel', format: 'dialog',
    },
    {
      id: 'dateUpdated', label: 'reviewDate', minWidth: 70, size: 'small', testid: 'reviewDateLabel',
    },
  ];

  return (
    <>
      <StickyHeadTable
        columns={columns}
        rows={data}
        id="latestProcessedUploadsTable"
        isLoading={isLoading}
        user={user}
        schoolList={null}
        reloadData={reloadData}
      />
    </>
  );
};

const LatestProcessedUploadsTable = withStyles(LatestProcessedUploadsTableStyles)(LatestProcessedUploadsTableComponent);
LatestProcessedUploadsTable.displayName = 'LatestProcessedUploadsTable';

export default LatestProcessedUploadsTable;
