import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

export type UploaderAppFn = (selector: string) => void;

const UploaderApp = async (selector: string) => {
  const uploaderContainer = document.getElementById(selector);
  if (!uploaderContainer) {
    // eslint-disable-next-line no-console
    console.error(
      'App Unable to mount Uploader application to selector: ',
      selector,
    );
    return;
  }

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById(selector),
  );
};

export default UploaderApp;
