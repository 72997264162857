import axios, { AxiosInstance } from 'axios';

// can be moved in separate models directory
export interface NetworkManagerConfig {
  baseURL: string,
  headers: {}
}

// can be moved in separate models directory
export interface HostSettings {
  enableLogging: boolean,
}

export default abstract class AxiosInstanceInitializer {
  private networkInstance: AxiosInstance;

  private enableLogging;

  constructor(config:NetworkManagerConfig, hostConfig: HostSettings) {
    this.networkInstance = axios.create(config);
    this.enableLogging = hostConfig.enableLogging;
    this.setRequestInterceptor();
    this.setResponseInterceptor();
  }

  abstract setRequestInterceptor() : void

  protected setResponseInterceptor(): void {
    this.networkInstance.interceptors.response.use((response) => {
      this.printResponse(response);
      return response.data;
    }, (err) => {
      this.printResponse(err);
      return Promise.reject(err);
    });
  }

  protected printResponse(response: unknown): void {
    if (this.enableLogging) {
      // eslint-disable-next-line no-console
      console.log(response);
    }
  }

  public getAxiosInstance(): AxiosInstance {
    return this.networkInstance;
  }

  protected isLoggingEnabled(): boolean {
    return this.enableLogging;
  }
}
