import React, { createContext, FC, useState } from 'react';

type AppContextState = {
  errorMessage: string;
  setErrorMessage: (name: string) => void;
  openDialogCount: number;
  setOpenDialogCount: (count: number) => void;
};

const contextDefaultValues: AppContextState = {
  errorMessage: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setErrorMessage: () => { },
  openDialogCount: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setOpenDialogCount: () => { },
};

export const AppContext = createContext<AppContextState>(
  contextDefaultValues,
);

const AppContextProvider: FC = ({ children }) => {
  const [errorMessage, setMessage] = useState<string>(contextDefaultValues.errorMessage);
  const [openDialogCount, setDialogCount] = useState<number>(contextDefaultValues.openDialogCount);
  const setErrorMessage = (newMessage: string) => {
    setMessage(newMessage);
    setTimeout(() => setMessage(''), 5000);
  }
  const setOpenDialogCount = (count: number) => {
    setDialogCount(count);
  };
  return (
    <AppContext.Provider
      value={{
        errorMessage,
        setErrorMessage,
        openDialogCount,
        setOpenDialogCount,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
