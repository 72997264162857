import UploaderApi from '../Host/Uploader'
import { User } from '../../models/User';
import { TableDataSource } from '../../models/TableDataSource';
import ErrorDetail from '../../util/ErrorDetail';
import { PresignedData } from '../../models/PresignedData';
import { SchoolPartner } from '../../models/SchoolPartner';
import AWS_FILE_ENUM from '../../enum/AWSFileEnum';

export const getUserInfo = async () : Promise<User | ErrorDetail> => {
  try {
    const newUser = await UploaderApi.post('/api/v1/userInfo/') as User;
    return newUser;
  } catch (err) {
    return new ErrorDetail(err);
  }
}

export const getPendingUploadData = async (districtID: number) : Promise<TableDataSource[] | ErrorDetail> => {
  try {
    const tableData = await UploaderApi.get(`/api/v1/uploads/PENDING?districtId=${districtID}`) as TableDataSource[];
    return tableData;
  } catch (err) {
    return new ErrorDetail(err);
  }
}

export const getProcessedUploadData = async (districtID: number) : Promise<TableDataSource[] | ErrorDetail> => {
  try {
    const tableData = await UploaderApi.get(`/api/v1/uploads/PROCESSED?districtId=${districtID}`) as TableDataSource[];
    return tableData;
  } catch (err) {
    return new ErrorDetail(err);
  }
}

export const validateUploadInfo = async (uploadID: number) : Promise<string | ErrorDetail> => {
  try {
    const tableData = await UploaderApi.post(`/api/v1/uploads/${uploadID}/validate`) as string;
    return tableData;
  } catch (err) {
    return new ErrorDetail(err);
  }
}

export const updateUploadInfo = async (uploadId: number, status: string) : Promise<boolean | ErrorDetail> => {
  try {
    await UploaderApi.post(`/api/v1/uploads/${uploadId}/status/${status}`);
    return true;
  } catch (err) {
    return new ErrorDetail(err);
  }
}

export const postUpload = async (districtId: number, schoolId: number|null, formData: FormData)
: Promise<PresignedData | ErrorDetail> => {
  try {
    const response = await UploaderApi.post(`/api/v1/upload?districtId=${districtId}&schoolId=${schoolId}`,
      formData, { headers: { 'Content-Type': 'multipart/form-data' } }) as PresignedData;
    return response;
  } catch (err) {
    return new ErrorDetail(err);
  }
}

export const getSchoolPartner = async (id: number): Promise<SchoolPartner[]|ErrorDetail> => {
  try {
    const response = await UploaderApi.get(`/api/v1/school/${id}/partner/96`) as SchoolPartner[];
    return response;
  } catch (err) {
    return new ErrorDetail(err);
  }
}

export const getNweaDistricts = async () : Promise<SchoolPartner[]|ErrorDetail> => {
  try {
    const response = await UploaderApi.get('/api/v1/districts/nwea') as SchoolPartner[];
    return response;
  } catch (err) {
    return new ErrorDetail(err);
  }
}

export const getUserDistrict = async (userDistrict: number, partnerId: number): Promise<SchoolPartner[]|ErrorDetail> => {
  try {
    const response = await UploaderApi.get(`/api/v1/school/${userDistrict}/partner/${partnerId}`) as SchoolPartner[];
    return response;
  } catch (err) {
    return new ErrorDetail(err);
  }
}

export const getUserDistrictPartner = async (userDistrict: number, partnerId: number, userId: number) : Promise<SchoolPartner[]|ErrorDetail> => {
  try {
    const response = await UploaderApi.get(`/api/v1/school/${userDistrict}/partner/${partnerId}/user/${userId}`) as SchoolPartner[];
    return response;
  } catch (err) {
    return new ErrorDetail(err);
  }
}

export const getSchoolMappings = async (uploadId: number) : Promise<TableDataSource|ErrorDetail> => {
  try {
    const response = await UploaderApi.get(`/api/v1/upload/${uploadId}/school/mappings`) as TableDataSource;
    return response;
  } catch (err) {
    return new ErrorDetail(err);
  }
}

export const postSchoolMappings = async (Id: number, mappedSchools: {}) : Promise<void|ErrorDetail> => {
  try {
    await UploaderApi.post(`/api/v1/upload/${Id}/school/mappings`, JSON.stringify(mappedSchools));
    return undefined;
  } catch (err) {
    return new ErrorDetail(err);
  }
}

export const postNextStep = async (uploadId: number) : Promise<void|ErrorDetail> => {
  try {
    await UploaderApi.post(`/api/v1/upload/${uploadId}/next-step`);
    return undefined;
  } catch (err) {
    return new ErrorDetail(err);
  }
}

export const getDownloadLink = async (uploadId: number, fileType: AWS_FILE_ENUM) : Promise<PresignedData|ErrorDetail> => {
  try {
    const response = await UploaderApi.get(`/api/v1/download/${uploadId}?s3FileEnum=${fileType}`) as PresignedData;
    return response;
  } catch (err) {
    return new ErrorDetail(err);
  }
}

export const checkStatus = async (districtId: number, timestamp: string) : Promise<boolean|ErrorDetail> => {
  try {
    const response = await UploaderApi.get(`/api/v1/district/${districtId}/check/status`,
      { params: { timestamp } }) as boolean;
    return response;
  } catch (err) {
    return new ErrorDetail(err);
  }
}

export const postRejectUpload = async (uploadId: number) : Promise<boolean|ErrorDetail> => {
  try {
    const response = await UploaderApi.post(`/api/v1/uploads/${uploadId}/reject`) as boolean
    return response;
  } catch (err) {
    return new ErrorDetail(err);
  }
}
