import React, { useCallback, useContext } from 'react';
import {
  createStyles, Theme, WithStyles, withStyles,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useIntl } from 'react-intl';
import TypographyRolloverIntl from '../rollovers/TypographyRolloverIntl';
import { AppContext } from '../../context/AppContext';

const resultPopupStyle = (theme: Theme) => createStyles({
  closeButtonX: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  root: {
    '& > *': {
      margin: theme.spacing(2),
    },
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  xSmall: {
    fontSize: 'x-small',
    width: '100px',
  },
  containerStyle: {
    overflow: 'hidden',
    alignItems: 'center',
    textAlign: 'center',
    verticalAlign: 'middle',
    height: 20,
    width: '100%',
    minWidth: '20rem',
    minHeight: '10rem',
    fontWeight: 'bold',
    marginTop: '2rem',
    cursor: 'pointer',
    color: '#d5413e',
  },
  buttonLinkA: {
    marginTop: '25px',
    paddingLeft: '150px',
    paddingRight: '150px',
    paddingButton: '100px',
    marginButton: '25px',
    top: '50px',
  },
  ceneterd: {
    display: 'flex',
    alignContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
});

export interface ErrorPopupProps extends Partial<WithStyles<typeof resultPopupStyle>> {
  errorMsg: string,
}

const ErrorComponent = ({
  classes, errorMsg,
}: ErrorPopupProps) => {
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(false);
  const intl = useIntl();
  const { setOpenDialogCount } = useContext(AppContext);

  const handleClickOpen = () => {
    setOpenDialogCount(1);
    setOpen(true);
  };
  const handleClose = () => {
    setOpenDialogCount(0);
    setOpen(false);
  };

  const handleEscapeKey = useCallback((event) => {
    if (event.keyCode === 27) {
      event.preventDefault();
      if (handleClose) handleClose()
    }
  }, []);

  const preFormatMessage = (msg: string): string => {
    const defaultMessage = intl.formatMessage({ id: 'unknown' });
    try {
      if (msg === null || msg === '') {
        return defaultMessage;
      }
      const msgArr = msg.trim().split(' ');
      if (msgArr.length === 0) {
        return defaultMessage;
      }

      if (msgArr.length === 1) {
        return intl.formatMessage({ id: msgArr[0], defaultMessage }, { info: '' }).trim();
      }
      return intl.formatMessage({ id: msgArr[0], defaultMessage }, { info: `: ${msgArr.slice(1, msgArr.length).join(' ')}` });
    } catch (ex) {
      return defaultMessage;
    }
  }

  return (
    <>
      <Button variant="outlined" role="button" color="primary" onClick={handleClickOpen} data-testid="UploadFailedPopupLabel">
        <TypographyRolloverIntl
          messageId="uploadFailed"
          typographyProps={{ className: classes?.xSmall }}
        />
      </Button>
      <Dialog
        fullWidth={fullWidth}
        open={open}
        onClose={handleClose}
        role="dialog"
        aria-modal="true"
        aria-labelledby="dialog-title-error"
        onKeyUp={handleEscapeKey}
      >
        <DialogTitle hidden id="dialog-title-error">
          <TypographyRolloverIntl
            messageId="uploadFailed"
          />
        </DialogTitle>
        <DialogContent tabIndex={0} id="dialog-error-description">
          <DialogContentText className={` ${classes?.containerStyle}`} data-testid="uploadFailedPopupMessage">
            <span className={classes?.ceneterd}>
              <ErrorIcon />
              {`${intl.formatMessage({ id: 'error' })}!! ${intl.formatMessage({ id: 'uploadFailed' })} `}
            </span>
            <span>{preFormatMessage(errorMsg)}</span>
          </DialogContentText>
        </DialogContent>
        <IconButton
          aria-label={intl.formatMessage({ id: 'closeX' })}
          className={classes?.closeButtonX}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>
    </>
  );
};

const ErrorPopup = withStyles(resultPopupStyle)(ErrorComponent);
ErrorPopup.displayName = 'ErrorPopup';

export default ErrorPopup;
