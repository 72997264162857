const AppMessages = {
  en: {
    errors: 'Errors:',
    error: 'Error',
    chooseSchool: 'Select a school',
    chooseSchoolError: 'Select the school(s) for this upload',
    chooseDistrict: 'Select a district',
    chooseDistrictError: 'Select a district',
    chooseUploadtype: 'Choose upload type',
    chooseUploadtypeError: 'Choose upload type',
    chooseFile: 'Select CDF File',
    nweaImportHeader: 'NWEA RIT score import',
    newUpload: 'New Upload',
    multipleSchools: 'Multiple Schools',
    upload: 'Upload',
    pendingUploads: 'Pending Uploads',
    fileName: 'File Name',
    schools: 'School(s)',
    uploadType: 'Upload Type',
    uploadedBy: 'Uploaded By',
    reviewedBy: 'Reviewed By',
    dateUpdated: 'Date Updated',
    reviewDate: 'Review Date',
    latestUploads: 'Latest Processed Uploads',
    nextStep: 'Next Step',
    initialOption: 'Initial',
    updateOption: 'Update',
    subsequentOption: 'Subsequent',
    review: 'Review',
    processedSuccess: 'Processed Successfully',
    uploadFailed: 'Upload Failed',
    multiple: 'Multiple',
    processedDate: 'Processed Date',
    rowsPerPage: 'Rows per page:',
    schoolMappingHeader: 'School Mapping',
    schoolNameFromNwea: 'School name from NWEA',
    matchedAchieveSchool: 'Matched Achieve3000 school',
    continueButton: 'Continue',
    cancelButton: 'Cancel',
    schoolMappingButton: 'School Mapping',
    noSchoolMapError: 'Please map at least one school.',
    records: 'Records',
    of: 'of',
    submit: 'Submit',
    uploadStatus: 'Upload Status',
    previousPage: 'Previous page',
    nextPage: 'Next page',
    nextSteps: 'Next Steps',
    attachFile: 'Attach File',
    noRowMessageText_pendingUploadsTable: 'No uploads have been submitted for this school year.',
    noRowMessageText_latestProcessedUploadsTable: 'No uploads have been successfully processed for this school year.',
    uploading: 'Uploading',
    uploaded: 'Uploaded',
    reviewButton: 'Review',
    reviewMatchedUser: 'Review matched users',
    reviewUnmatchedUser: 'Review unmatched users',
    reviewProcessedUser: 'Review processed users',
    reviewUnProcessedUser: 'Review unprocessed users',
    processButton: 'Process',
    rejectButton: 'Reject',
    closeX: 'Close',
    processing: 'Processing',
    errorDownloadingMatched: 'Error Downloading MATCHED USER Records csv file.',
    errorDownloadingUnMatched: 'Error Downloading UNMATCHED USER Records csv file.',
    errorDownloadingProcessed: 'Error Downloading PROCESSED USER Records csv file.',
    errorDownloadingUnProcessed: 'Error Downloading UNPROCESSED USER Records csv file.',
    unknown: 'Unknown Error',

    // These strings are being passed as errors from backend So not following camel case
    // where info can be injected as optional data dynamically if we want to
    missing_headers: 'Missing Header: {info}',
    unable_to_parse_csv_in_this_upload: 'Unable to parse CSV in this upload {info}',
    no_schools_to_be_mapped: 'No schools to be mapped {info}',
    file_length_not_equal_matched_and_unmatched_users: 'File length not equal matched and unmatched users {info}',
    no_data_to_process: 'No data to process {info}',
    unknown_error: 'Unknown error, Please try again.',
  },
  'en-es': {
    errors: 'Errors:',
    'errors.rollover': 'Errores:',
    error: 'Error',
    'error.rollover': 'Error',
    chooseSchool: 'Seleccione una escuela',
    'chooseSchool.rollover': 'Seleccione una escuela',
    chooseSchoolError: 'Select the school(s) for this upload',
    'chooseSchoolError.rollover': 'Seleccione la(s) escuela(s) para esta carga',
    chooseDistrict: 'Seleccione un distrito',
    'chooseDistrict.rollover': 'Seleccione un distrito',
    chooseDistrictError: 'Select a district',
    'chooseDistrictError.rollover': 'Seleccione un distrito',
    chooseUploadtype: 'Elija el tipo de carga',
    'chooseUploadtype.rollover': 'Elija el tipo de carga',
    chooseUploadtypeError: 'Choose upload type',
    'chooseUploadtypeError.rollover': 'Elija el tipo de carga',
    chooseFile: 'Seleccionar archivo CDF',
    'chooseFile.rollover': 'Seleccionar archivo CDF',
    nweaImportHeader: 'NWEA RIT score import',
    'nweaImportHeader.rollover': 'Importación de puntajes NWEA RIT',
    newUpload: 'New Upload',
    'newUpload.rollover': 'Nueva carga',
    multipleSchools: 'Multiple Schools',
    upload: 'Upload',
    'upload.rollover': 'Subir',
    pendingUploads: 'Pending Uploads',
    'pendingUploads.rollover': 'Subidas pendientes',
    'fileName.rollover': 'Nombre del archivo',
    'schools.rollover': 'Escuela(s)',
    'uploadType.rollover': 'Tipo de carga',
    'uploadedBy.rollover': 'Subido por',
    'reviewChanges.rollover': 'Revisar cambios',
    'reviewedBy.rollover': 'Revisado por',
    'reviewDate.rollover': 'Fecha de revisión',
    'latestUploads.rollover': 'Últimas cargas procesadas',
    fileName: 'File Name',
    schools: 'School(s)',
    uploadType: 'Upload Type',
    uploadedBy: 'Uploaded By',
    nextStep: 'Next Step',
    'nextStep.rollover': 'Próximo paso',
    reviewedBy: 'Reviewed By',
    reviewDate: 'Review Date',
    latestUploads: 'Latest Processed Uploads',
    initialOption: 'Initial',
    'initialOption.rollover': 'Inicial',
    updateOption: 'Update',
    'updateOption.rollover': 'Actualizar',
    subsequentOption: 'Subsequent',
    'subsequentOption.rollover': 'Subsecuente',
    review: 'Review',
    'review.rollover': 'Revisar',
    processedSuccess: 'Processed Successfully',
    'processedSuccess.rollover': 'Procesado con éxito',
    uploadFailed: 'Upload Failed',
    'uploadFailed.rollover': 'Subida fallida',
    multiple: 'Multiple',
    'multiple.rollover': 'Múltiple',
    processedDate: 'Processed Date',
    'processedDate.rollover': 'Fecha procesada',
    rowsPerPage: 'Rows per page:',
    'rowsPerPage.rollover': 'Filas por página:',
    schoolMappingHeader: 'School Mapping',
    'schoolMappingHeader.rollover': 'Mapeo de la escuela ',
    schoolNameFromNwea: 'School name from NWEA',
    'schoolNameFromNwea.rollover': 'Nombre de la escuela de NWEA',
    matchedAchieveSchool: 'Matched Achieve3000 school',
    'matchedAchieveSchool.rollover': 'Escuela Achieve3000 igualada',
    continueButton: 'Continue',
    'continueButton.rollover': 'Continuar',
    cancelButton: 'Cancel',
    'cancelButton.rollover': 'Cancelar',
    schoolMappingButton: 'School Mapping',
    'schoolMappingButton.rollover': 'Mapeo de la escuela',
    noSchoolMapError: 'Please map at least one school.',
    'noSchoolMapError.rollover': 'Por favor mapee al menos una escuela.',
    of: 'of',
    'of.rollover': 'de',
    'page.rollover': 'registro',
    uploadStatus: 'Upload Status',
    'uploadStatus.rollover': 'Estado de carga',
    submit: 'Submit',
    'submit.rollover': 'Enviar',
    previousPage: 'Pagina anterior',
    'previousPage.rollover': 'Pagina anterior',
    nextPage: 'Siguiente página',
    'nextPage.rollover': 'Siguiente página',
    records: 'Records',
    'records.rollover': 'Registros',
    nextSteps: 'Next Steps',
    'nextSteps.rollover': 'Próximos pasos',
    attachFile: 'Attach File',
    'attachFile.rollover': 'Adjuntar archivo',
    noRowMessageText_pendingUploadsTable: 'No uploads have been submitted for this school year.',
    'noRowMessageText_pendingUploadsTable.rollover': 'No se han enviado cargas para este año escolar.',
    noRowMessageText_latestProcessedUploadsTable: 'No uploads have been successfully processed for this school year.',
    'noRowMessageText_latestProcessedUploadsTable.rollover': 'No se ha procesado con éxito ninguna carga para este año escolar.',
    uploading: 'Uploading',
    'uploading.rollover': 'Subiendo',
    Uploading: 'Uploading',
    'Uploading.rollover': 'Subiendo',
    uploaded: 'Uploaded',
    'uploaded.rollover': 'Subido',
    Uploaded: 'Uploaded',
    'Uploaded.rollover': 'Subido',
    reviewButton: 'Review',
    'reviewButton.rollover': 'Revisión',
    reviewMatchedUser: 'Review matched users',
    'reviewMatchedUser.rollover': 'Revisar usuarios coincidentes',
    reviewUnmatchedUser: 'Review unmatched users',
    'reviewUnmatchedUser.rollover': 'Revisar usuarios incomparables',
    reviewProcessedUser: 'Review processed users',
    'reviewProcessedUser.rollover': 'Revisar usuarias procesadas',
    reviewUnProcessedUser: 'Review unprocessed users',
    'reviewUnProcessedRecord.rollover': 'Revisar usuarias no procesadas',
    processButton: 'Process',
    'processButton.rollover': 'Proceso',
    rejectButton: 'Reject',
    'rejectButton.rollover': 'Rechazar',
    processing: 'Processing',
    'processing.rollover': 'Procesando',
    errorDownloadingMatched: 'Error Downloading MATCHED USER Records csv file',
    'errorDownloadingMatched.rollver': 'Error al descargar el archivo csv de registros de USUARIO COINCIDENTE.',
    errorDownloadingUnMatched: 'Error Downloading UNMATCHED USER Records csv file',
    'errorDownloadingUnMatched.rollover': 'Error al descargar el archivo csv de registros de USUARIO SIN COINCIDIR.',
    errorDownloadingProcessed: 'Error Downloading PROCESSED USER Records csv file.',
    'errorDownloadingProcessed.rollver': 'Error al descargar el archivo csv de registros de USUARIO PROCESADO.',
    errorDownloadingUnProcessed: 'Error Downloading UNPROCESSED USER Records csv file.',
    'errorDownloadingUnProcessed.rollover': 'Error al descargar el archivo csv de registros de USUARIO SIN PROCESAR.',
    unknown: 'Unknown Error',
    'unknown.rollover': 'Error desconocido',

    // These strings are being passed as errors from backend So have to Write it as it is
    missing_headers: 'Missing Header: {info}',
    'missing_header.rollover': 'Encabezado faltante: {info}',
    unable_to_parse_csv_in_this_upload: 'Unable to parse CSV in this upload {info}',
    'unable_to_parse_csv_in_this_upload.rollover': 'No se puede analizar CSV en esta carga {info}',
    no_schools_to_be_mapped: 'No schools to be mapped {info}',
    'no_schools_to_be_mapped.rollover': 'No hay escuelas para mapear {info}',
    file_length_not_equal_matched_and_unmatched_users: 'File length not equal matched and unmatched users {info}',
    'file_length_not_equal_matched_and_unmatched_users.rollover':
    'La longitud del archivo no es igual a los usuarios coincidentes y no coincidentes {info}',
    no_data_to_process: 'No data to process {info}',
    'no_data_to_process.rollover': 'No hay datos para procesar {info}',
    unknown_error: 'Unknown error, Please try again.',
    'unknown_error.rollover': 'Error desconocido, inténtalo de nuevo.',
  },
  es: {
    errors: 'Errores:',
    error: 'Error',
    chooseSchool: 'Seleccione una escuela',
    chooseSchoolError: 'Seleccione la(s) escuela(s) para esta carga',
    chooseDistrict: 'Seleccione un distrito',
    chooseDistrictError: 'Seleccione un distrito',
    chooseUploadtype: 'Elija el tipo de carga',
    chooseUploadtypeError: 'Elija el tipo de carga',
    chooseFile: 'Seleccionar archivo CDF',
    nweaImportHeader: 'Importación de puntajes NWEA RIT',
    newUpload: 'Nueva carga',
    multipleSchools: 'Varias escuelas',
    upload: 'Subir',
    pendingUploads: 'Subidas pendientes',
    fileName: 'Nombre del archivo',
    schools: 'Escuela(s)',
    uploadType: 'Tipo de carga',
    uploadedBy: 'Subido por',
    nextStep: 'Próximo paso',
    reviewedBy: 'Revisado por',
    reviewDate: 'Fecha de revisión',
    latestUploads: 'Últimas cargas procesadas',
    initialOption: 'Inicial',
    updateOption: 'Actualizar',
    subsequentOption: 'Subsecuente',
    review: 'Revisar',
    processedSuccess: 'Procesado con éxito',
    uploadFailed: 'Subida fallida',
    multiple: 'Múltiple',
    processedDate: 'Fecha procesada',
    rowsPerPage: 'Filas por página:',
    schoolMappingHeader: 'Mapeo de la escuela',
    schoolNameFromNwea: 'Nombre de la escuela de NWEA',
    matchedAchieveSchool: 'Escuela Achieve3000 igualada',
    continueButton: 'Continuar',
    cancelButton: 'Cancelar',
    schoolMappingButton: 'Mapeo de la escuela',
    noSchoolMapError: 'Por favor mapee al menos una escuela.',
    of: 'de',
    uploadStatus: 'Estado de carga',
    submit: 'Enviar',
    previousPage: 'Pagina anterior',
    nextPage: 'Siguiente página',
    records: 'Registros',
    nextSteps: 'Próximos pasos',
    attachFile: 'Adjuntar archivo',
    noRowMessageText_pendingUploadsTable: 'No se han enviado cargas para este año escolar.',
    noRowMessageText_latestProcessedUploadsTable: 'No se ha procesado con éxito ninguna carga para este año escolar.',
    uploading: 'Subiendo',
    Uploading: 'Subiendo',
    uploaded: 'Subido',
    Uploaded: 'Subido',
    reviewButton: 'Revisión',
    reviewMatchedUser: 'Revisar usuarios coincidentes',
    reviewUnmatchedUser: 'Revisar usuarios incomparables',
    reviewProcessedUser: 'Revisar usuarias procesadas',
    reviewUnProcessedUser: 'Revisar usuarias no procesadas',
    processButton: 'Proceso',
    rejectButton: 'Rechazar',
    closeX: 'Cerrar',
    processing: 'Procesando',
    errorDownloadingMatched: 'Error al descargar el archivo csv de registros de USUARIO COINCIDENTE.',
    errorDownloadingUnMatched: 'Error al descargar el archivo csv de registros de USUARIO SIN COINCIDIR.',
    errorDownloadingProcessed: 'Error al descargar el archivo csv de registros de USUARIO PROCESADO.',
    errorDownloadingUnProcessed: 'Error al descargar el archivo csv de registros de USUARIO SIN PROCESAR.',
    unknown: 'Error desconocido',

    // These strings are being passed as errors from backend So have to Write it as it is
    missing_headers: 'Encabezado faltante: {info}',
    unable_to_parse_csv_in_this_upload: 'No se puede analizar CSV en esta carga {info}',
    no_schools_to_be_mapped: 'No hay escuelas para mapear {info}',
    file_length_not_equal_matched_and_unmatched_users: 'La longitud del archivo no es igual a los usuarios coincidentes y no coincidentes {info}',
    no_data_to_process: 'No hay datos para procesar {info}',
    unknown_error: 'Error desconocido, inténtalo de nuevo.',
  },
};
export default AppMessages;
