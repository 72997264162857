import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { AppContext } from '../../context/AppContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  uiText: {
    justifyContent: 'center',
  },
}));

export default () => {
  const { errorMessage, setErrorMessage } = useContext(AppContext);
  const toggle = () => setErrorMessage('');
  const classes = useStyles();
  const length = errorMessage.length > 0;
  return (
    <div className={`${classes.root}`}>
      {length && (
        <Alert onClick={toggle} className={classes.uiText} severity="error" color="error">
          {errorMessage}
        </Alert>
      )}
    </div>
  );
}
