import { useEffect, useState } from 'react';
import { IntlShape } from 'react-intl';

const useRolloverIntl = (intl: IntlShape, id: string) => {
  const message = intl.formatMessage({ id });
  const rollover = intl.formatMessage({ id: `${id}.rollover`, defaultMessage: ' ' });

  const [text, setText] = useState(message);

  useEffect(() => {
    setText(message);
  }, [message]);

  if (rollover === ' ') {
    return { text, events: {} };
  }

  return {
    text,
    events: {
      onMouseEnter: () => {
        setText(rollover);
      },
      onMouseLeave: () => {
        setText(message);
      },
    },
  };
};

export default useRolloverIntl;
