export default (url: string, fileName: string) => {
  const link = document.createElement('a');
  link.download = fileName;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    link.parentNode?.removeChild(link);
  }, 400);
}
