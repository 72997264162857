import React from 'react';
import { useIntl } from 'react-intl';

import { Typography } from '@material-ui/core';

import useRolloverIntl from '../../hooks/useRolloverIntl';

export interface TypographyRolloverIntlProps {
  messageId: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  typographyProps?: object;
}

const TypographyRolloverIntl = ({ messageId, typographyProps }: TypographyRolloverIntlProps) => {
  const intl = useIntl();

  const { text, events } = useRolloverIntl(intl, messageId);

  return (
    <Typography component="span" {...typographyProps} {...events}>
      {text}
    </Typography>
  );
};

export default TypographyRolloverIntl;
