import ErrorDetails from '../models/ErrorDetail'

class ErrorDetail implements ErrorDetails {
  status: number;

  message: string;

  statusText: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(ex: any) {
    // eslint-disable-next-line prefer-destructuring
    const response = ex?.response;
    this.status = response?.statusText || -1;
    this.message = response?.data?.message || response?.data?.error || ex.message || 'Unknown Error';
    this.statusText = response?.statusText || 'Server Error';
  }

  toString(): string {
    return `Status Code= ${this.status}, Message= ${this.message}, Status Text = ${this.statusText}`
  }
}

export default ErrorDetail;
