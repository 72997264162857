import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  createStyles, Theme, WithStyles, withStyles,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {
  Table, TableBody, TableHead, TableRow, Typography,
} from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import { useIntl } from 'react-intl';
import LinearProgress from '../Animations/LinearProgress';
import TypographyRolloverIntl from '../rollovers/TypographyRolloverIntl';
import { User } from '../../models/User';
import { AutoCompleteSource } from '../../models/AutoCompleteSource';
import StyledAutoComplete from '../shared/StyledAutoComplete';
import { TableDataSource } from '../../models/TableDataSource';
import { SchoolMatching } from '../../models/SchoolMatching';
import {
  getSchoolMappings, getSchoolPartner, postRejectUpload, postSchoolMappings,
} from '../../service/api/Uploader';
import ErrorDetail from '../../util/ErrorDetail';
import { AppContext } from '../../context/AppContext';

const schoolMappingStyle = (theme: Theme) => createStyles({
  header: {
    fontSize: 'x-large',
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginLeft: '30%',
    padding: theme.spacing(5),
    position: 'relative',
    left: '30px',
  },
  closeButtonX: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  tableStyling: {
    marginBottom: '100px',
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(0),
    border: 'none',
    overflowX: 'auto',
  },
  tableTitle: {
    color: 'black',
    borderLeft: 'none',
    borderBottom: '1px solid black',
    paddingLeft: 30,
    width: 280,
    paddingRight: 5,
    backgroundColor: 'white',
  },
  tableTitleA: {
    color: 'black',
    borderLeft: '1px solid lightGray',
    borderBottom: '1px solid black',
    paddingLeft: 10,
    width: 200,
    paddingRight: 50,
    backgroundColor: 'white',
  },
  tableHeader: {
    fontSize: 19,
    fontWeight: 'bold',
    paddingLeft: 50,
    textAlign: 'center',
  },
  cellColumn: {
    borderLeft: 'none',
    borderBottom: 'none',
    width: 100,
    paddingLeft: 10,
  },
  dropDownStyle: {
    width: 230,
  },
  root: {
    '& > *': {
      margin: theme.spacing(2),
    },
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  outlinedInput: {
    width: '330px',
  },
  dropDownSize: {
    marginTop: theme.spacing(0),
  },
  textStyle: {
    fontSize: 14,
    textIndent: '20px',
    paddingBottom: theme.spacing(5),
    fontAlign: 'center',
  },
  dropDownColumn: {
    borderLeft: '1px solid lightGray',
    borderBottom: 'none',
    paddingBottom: theme.spacing(6),
  },
  xSmall: {
    fontSize: 'x-small',
    width: '100px',
  },
  containerStyle: {
    overflow: 'hidden',
    alignItems: 'center',
    minHeight: 400,
  },
  dialogContentStyle: {
    paddingTop: '0px',
  },
  errorContent: {
    color: 'red',
    textAlign: 'center',
  },
  btnDisabled: {
    opacity: 0.7,
  },
  btnGrey: {
    backgroundColor: '#595959',
  },
});

export interface SchoolMappingProps extends Partial<WithStyles<typeof schoolMappingStyle>> {
  user: User | null;
  row: TableDataSource;
  schoolList: AutoCompleteSource[] | null;
  reloadData: () => boolean;
}

const SchoolMappingComponent = ({
  classes, row, schoolList, reloadData,
}: SchoolMappingProps) => {
  const [selectableSchoolList, setSelectableSchoolList] = useState<AutoCompleteSource[] | null>(schoolList);
  const [uploadedSchoolList, setUploadedSchoolList] = useState<SchoolMatching[]>(row.uploadSchoolMatchingList);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [showSchoolsDropDown, setShowSchoolsDropdown] = useState<boolean>(false);
  const [mappedSchools, setMappedSchools] = useState<SchoolMatching[]>([]);
  const intl = useIntl();
  const [schoolMapError, setSchoolMapError] = useState('');
  const { setErrorMessage, setOpenDialogCount } = useContext(AppContext);

  async function getUploadedSchools(uploadId: number) {
    const uploaderStatusResponse = await getSchoolMappings(uploadId);
    if (uploaderStatusResponse instanceof ErrorDetail) {
      setUploadedSchoolList([]);
      setErrorMessage(uploaderStatusResponse.message);
      return;
    }
    if (uploaderStatusResponse.uploadSchoolMatchingList.length > 0) {
      setUploadedSchoolList(uploaderStatusResponse.uploadSchoolMatchingList)
    }
  }
  async function rejectDownload(uploadId: number) {
    setIsLoading(true);
    const response = await postRejectUpload(uploadId);
    setIsLoading(false);
    if (response instanceof ErrorDetail) {
      setErrorMessage(response.message);
    }
  }

  const handleClickOpen = () => {
    if (uploadedSchoolList.length === 0) {
      getUploadedSchools(row.id)
    }
    setOpenDialogCount(1);
    setOpen(true);
  };
  const handleClose = () => {
    if (schoolMapError !== '') {
      setSchoolMapError('');
    }
    setOpenDialogCount(0);
    setOpen(false);
  };

  const handleReject = () => {
    rejectDownload(row.id);
    handleClose();
    reloadData();
  };

  async function sendMappedSchools(id: number) {
    setIsLoading(true);
    const uploaderResponse = await postSchoolMappings(id, mappedSchools);
    setIsLoading(true);
    if (uploaderResponse instanceof ErrorDetail) {
      setErrorMessage(uploaderResponse.message);
    }
    setOpenDialogCount(0);
    setOpen(false);
    reloadData();
  }

  const handleContinue = () => {
    if (mappedSchools.length > 0) {
      sendMappedSchools(row.id)
    } else {
      setSchoolMapError(intl.formatMessage({ id: 'noSchoolMapError' }))
    }
  };

  const handleEscapeKey = useCallback((event) => {
    if (event.keyCode === 27) {
      event.preventDefault();
      if (handleClose) handleClose()
    }
  }, []);

  async function fetchSchoolList(id: number) {
    setShowSchoolsDropdown(true);
    const schools = await getSchoolPartner(id);
    if (schools instanceof ErrorDetail) {
      // eslint-disable-next-line no-console
      console.log('Error With School list in school mapping popout');
      setErrorMessage(schools.message);
      return;
    }
    setSelectableSchoolList(schools);
  }

  useEffect(
    () => {
      if (open) {
        if (selectableSchoolList && selectableSchoolList.length === 0) {
          fetchSchoolList(row.districtId)
        } else if (selectableSchoolList && selectableSchoolList.length > 0) {
          setShowSchoolsDropdown(true)
        }
      }
    },
    [open],
  );

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleClickOpen} data-testid="schoolMappingButtonLabel">
        <TypographyRolloverIntl
          messageId="schoolMappingButton"
          typographyProps={{ className: classes?.xSmall }}
        />
      </Button>
      <Dialog
        fullWidth={fullWidth}
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="SchoolMappingHeader"
        onKeyUp={handleEscapeKey}
      >
        <IconButton
          aria-label={intl.formatMessage({ id: 'closeX' })}
          className={classes?.closeButtonX}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id="SchoolMappingHeader">
          <TypographyRolloverIntl
            messageId="schoolMappingHeader"
            typographyProps={{ className: classes?.header }}
          />
        </DialogTitle>
        <DialogContent className={classes?.dialogContentStyle}>
          <Table
            stickyHeader
            aria-label="School Mapping table"
            className={classes?.tableStyling}
            data-testid="schoolMappingTableLabel"
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes?.tableTitle} data-testid="schoolNameFromNweaLabel">
                  <TypographyRolloverIntl
                    messageId="schoolNameFromNwea"
                    typographyProps={{ classes: { root: classes?.tableHeader } }}
                  />
                </TableCell>
                <TableCell className={classes?.tableTitleA} data-testid="matchedAchieveSchoolLabel">
                  <TypographyRolloverIntl
                    messageId="matchedAchieveSchool"
                    typographyProps={{ classes: { root: classes?.tableHeader } }}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row.uploadSchoolMatchingList.sort((a, b) => a.uploadSchoolName.localeCompare(b.uploadSchoolName)).map((uploadSchoolMatching) => {
                const schoolMapping: SchoolMatching | null = mappedSchools.find((s) => s.id === uploadSchoolMatching.id) || null;
                let chosenItem: AutoCompleteSource | null = null;
                if (schoolMapping && schoolMapping.kbSchoolId && schoolMapping.kbSchoolName) {
                  chosenItem = { id: schoolMapping.kbSchoolId, name: schoolMapping.kbSchoolName }
                }

                if (!chosenItem) {
                  chosenItem = selectableSchoolList?.find((s) => s.name.trim() === uploadSchoolMatching.uploadSchoolName) || null
                  if (chosenItem != null) {
                    mappedSchools.push({
                      id: uploadSchoolMatching.id,
                      uploadSchoolName: uploadSchoolMatching.uploadSchoolName,
                      kbSchoolId: chosenItem.id,
                      kbSchoolName: chosenItem.name,
                    })
                  }
                  if (mappedSchools.length > 0 && schoolMapError !== '') {
                    setSchoolMapError('');
                  }
                }

                const remove = (i: SchoolMatching): SchoolMatching[] => mappedSchools.filter((item) => item.id !== i.id);

                const handleSchoolIdChange = (value: React.SetStateAction<AutoCompleteSource | null>) => {
                  if (value) {
                    if (schoolMapping) {
                      const filteredMapping = remove(schoolMapping)
                      setMappedSchools([{
                        id: uploadSchoolMatching.id,
                        uploadSchoolName: uploadSchoolMatching.uploadSchoolName,
                        kbSchoolId: (value as AutoCompleteSource).id,
                        kbSchoolName: (value as AutoCompleteSource).name,
                      }, ...filteredMapping]);
                    } else {
                      setMappedSchools([{
                        id: uploadSchoolMatching.id,
                        uploadSchoolName: uploadSchoolMatching.uploadSchoolName,
                        kbSchoolId: (value as AutoCompleteSource).id,
                        kbSchoolName: (value as AutoCompleteSource).name,
                      }, ...mappedSchools]);
                    }
                  } else if (!value && schoolMapping) {
                    setMappedSchools(remove(schoolMapping));
                  }
                }
                return (
                  <TableRow key={uploadSchoolMatching.id}>
                    <TableCell className={classes?.cellColumn}>
                      <Typography
                        data-testid="School Name"
                        className={classes?.textStyle}
                      >
                        {uploadSchoolMatching.uploadSchoolName}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes?.dropDownColumn}>
                      {showSchoolsDropDown && selectableSchoolList && (
                        <StyledAutoComplete
                          id={`chooseSchool-${uploadSchoolMatching.id}`}
                          key={uploadSchoolMatching.id}
                          list={selectableSchoolList}
                          chosenItem={chosenItem}
                          label="chooseSchool"
                          handleIdChange={handleSchoolIdChange}
                          disableAutoSelect
                          displayAll={false}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogContent>
          <DialogContentText
            className={classes?.errorContent}
            data-testid="schoolMappingErrorMsg"
            onClick={() => setSchoolMapError('')}
          >
            {schoolMapError}
          </DialogContentText>
        </DialogContent>
        {isLoading && <LinearProgress />}
        <DialogActions>
          <div className={classes?.root}>
            <Button
              onClick={handleContinue}
              disabled={isLoading}
              className={isLoading ? classes?.btnDisabled : ''}
              data-testid="continueButtonLabel"
            >
              <TypographyRolloverIntl
                messageId="continueButton"
                typographyProps={{ className: 'btn-cs btn-medium color-primary' }}
              />
            </Button>
            <Button onClick={handleClose} data-testid="cancelButtonLabel">
              <TypographyRolloverIntl
                messageId="cancelButton"
                typographyProps={{ className: 'btn-cs btn-medium color-cancel' }}
              />
            </Button>
            <Button
              onClick={handleReject}
              className={isLoading ? classes?.btnDisabled : ''}
              disabled={isLoading}
              data-testid="rejectButtonLabelSchlMap"
            >
              <TypographyRolloverIntl
                messageId="rejectButton"
                typographyProps={{ className: 'btn-cs btn-medium color-danger' }}
              />
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

const SchoolMapping = withStyles(schoolMappingStyle)(SchoolMappingComponent);
SchoolMapping.displayName = 'SchoolMapping';

export default SchoolMapping;
